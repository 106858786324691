/* eslint-disable */
import React from "react";
import Button from "react-bootstrap/Button";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import queryString from "query-string";
import { Link } from "gatsby";
import "../forgot-my-password/style.scss";
import axios from "axios";
import { getPasswordResetBaseURL } from "../../utils/getPasswordResetBaseURL";
import BRAND_CONFIG from '../../BRAND_CONFIG';
import { Form } from "react-bootstrap";
import HeaderLogo from "../../components/header/HeaderLogo";

export default class extends React.Component {
  state = {
    passwordResetMessage: "",
    validationMessage: "",
    password: "",
    confirmPassword: "",
  };

  constructor(props) {
    super(props);
  }

  resetPasswordClicked = () => {
    const { password, confirmPassword } = this.state;
    if (!password || !confirmPassword) {
      this.setState({
        validationMessage: BRAND_CONFIG?.pages?.resetPassword?.translations?.emptyPasswordError ?? 'You must enter your password in both fields',
      });
    } else if (password !== confirmPassword) {
      this.setState({
        validationMessage: BRAND_CONFIG?.pages?.resetPassword?.translations?.passwordsMathingError ?? "Your passwords do not match",
      });
    } else if (password.length < 6) {
      this.setState({
        validationMessage: BRAND_CONFIG?.pages?.resetPassword?.translations?.passwordLengthError ?? "Your password must be at least 6 characters long",
      });
    } else {
      const { code: resetCode } = queryString.parse(location.search);

      axios({
        method: "post",
        url: `${getPasswordResetBaseURL}/reset-account-password`,
        data: {
          resetCode,
          password,
        },
      })
        .then((response) => {
          if (response.data.errorMessage) {
            this.setState({
              validationMessage: response.data.errorMessage,
            });
          } else {
            this.setState({
              passwordResetMessage: response.data.message,
            });
          }
        })
        .catch((error) => {
          this.setState({
            passwordResetMessage: error.response.data.errorMessage,
          });
        });
    }
  };

  render() {
    const { passwordResetMessage, validationMessage, password, confirmPassword } = this.state;
    const { name, logo } = BRAND_CONFIG;

    return (
      <Layout>
        <SEO title="Reset Password" />
        <Container fluid className="page-wrapper reset-password">
          <Row>
            <Col>
                <div className="form-box">
                  {passwordResetMessage && (
                    <div className="password-success-reset-message">
                      <HeaderLogo title={passwordResetMessage} logo={logo} logoSize={100} />
                      <Link to="/login" className="button is-primary is-black">
                        <Button block>
                          Login
                        </Button>
                      </Link>
                    </div>
                  )}
                  {!passwordResetMessage && (
                    <div className="Login">
                      <HeaderLogo title={BRAND_CONFIG?.pages?.resetPassword?.translations?.heading ?? "Reset Password"} logo={logo} logoSize={100} />
                      <p>{BRAND_CONFIG?.pages?.resetPassword?.translations?.instructions ?? 'Please enter your new password below.'}</p>
                      {validationMessage && <div className="validation-message">{validationMessage}</div>}
                      <Form className="form-signin mt-4">
                        <Form.Group className="form-label-group">
                          <Form.Label for="email">{BRAND_CONFIG?.pages?.resetPassword?.translations?.newPassword ?? 'New Password'}</Form.Label>
                          <Form.Control
                            autoFocus
                            type="password"
                            id="password"
                            className="form-control"
                            required
                            value={password}
                            onChange={({ target }) => {
                              this.setState({
                                validationMessage: "",
                                password: target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="form-label-group">
                          <Form.Label for="email">{BRAND_CONFIG?.pages?.resetPassword?.translations?.confirmPassword ?? 'Confirm New Password'}</Form.Label>
                          <Form.Control
                            autoFocus
                            type="password"
                            id="confirm-password"
                            className="form-control"
                            required
                            value={confirmPassword}
                            onChange={({ target }) => {
                              this.setState({
                                validationMessage: "",
                                confirmPassword: target.value,
                              });
                            }}
                          />
                        </Form.Group>
                        <Button block onClick={this.resetPasswordClicked}>
                          {BRAND_CONFIG?.pages?.resetPassword?.translations?.reset ?? 'Reset Password'}
                        </Button>
                      </Form>
                    </div>
                  )}
                </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
